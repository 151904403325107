<template>
    <div>
        <CRow>
            <CCol sm="12" md="4">
                <h2>Reservation statuses</h2>
                <md-list>
                    <md-list-item v-for="reservation in reservations" v-bind:key="reservation.id">
                        <md-field>
                            <md-input v-model="reservation.name" @keyup="update(reservation)"></md-input>
                        </md-field>
                    </md-list-item>
                </md-list>
            </CCol>
            <CCol sm="12" md="4">
                <h2>Driver statuses</h2>
                <md-list>
                    <md-list-item v-for="driver in drivers" v-bind:key="driver.id">
                        <md-field>
                            <md-input v-model="driver.name" @keyup="update(driver)"></md-input>
                        </md-field>
                    </md-list-item>
                </md-list>
            </CCol>
            <CCol sm="12" md="4">
                <h2>Trip statuses</h2>
                <md-list>
                    <md-list-item v-for="trip in trips" v-bind:key="trip.id">
                        <md-field>
                            <md-input v-model="trip.name" @keyup="update(trip)"></md-input>
                        </md-field>
                    </md-list-item>
                </md-list>
            </CCol>
        </CRow>
        <md-snackbar :md-position="position" :md-duration="isInfinity ? Infinity : duration" :md-active.sync="showSnackbar" md-persistent>
            <span>Status saved</span>
        </md-snackbar>

    </div>
</template>    
<script>
import firebase from '../../Firebase'
export default {
    name: 'Statuses',
    data() {
        return {
            refReservation:  firebase.firestore().collection('statuses'),
            refDriver:  firebase.firestore().collection('statuses'),
            refTrip:  firebase.firestore().collection('statuses'),
            reservations: [],
            drivers: [],
            trips: [],
            showSnackbar: false,
            position: 'center',
            duration: 4000,
            isInfinity: false
        }
    },
    created() {
        this.refReservation.where("type", "==", "reservation").onSnapshot((querySnapshot) => {
            console.log('Snap shot')
            this.reservations = [];
            querySnapshot.forEach((doc) => {
               
                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                this.reservations.push(dataToSave);
                
            });
        });

        this.refDriver.where("type", "==", "driver").onSnapshot((querySnapshot) => {
            console.log('Snap shot')
            this.drivers = [];
            querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                this.drivers.push(dataToSave);
                
            });
        });

        this.refTrip.where("type", "==", "trip").onSnapshot((querySnapshot) => {
            console.log('Snap shot')
            this.trips = [];
            querySnapshot.forEach((doc) => {

                let dataToSave = doc.data();
                dataToSave.id = doc.id ;
                this.trips.push(dataToSave);
                
            });
        });
    },
    methods: {
        update(item) {
            console.log(item);
            let th = this;
            let ref = null;
            switch(item.type) {
                case 'reservation':
                    ref = this.refReservation
                    break
                case 'driver':
                    ref = this.refDriver
                    break
                case 'trip':
                    ref = this.refTrip
                    break
                
            }
            ref.doc(item.id).update(item)
            .then(function(){
                console.log('Updated');
                th.showSnackbar = true;
                
            }).catch(function(error) {
                console.log("Error getting document:", error);
            });
        },
        destroy() {
            let th = this;
            // this.ref.doc(this.$route.params.id).delete()
            // .then(function(){
            //     console.log('Deleted');
                
            // }).catch(function(error) {
            //     console.log("Error getting document:", error);
            // });
        }
    }
}
</script>